'use strict';

$(document).ready(function () {
	var sliderContainer= '.slider-category-product .slider';
	$(sliderContainer).each(function(){
		
		var sliderToDisplayInDesktop = $(this).closest('.slider-category-product').attr('sliderToDisplayInDesktop');
		var sliderToDisplayInTablet = $(this).closest('.slider-category-product').attr('sliderToDisplayInTablet');
		var sliderToDisplayInMobile = $(this).closest('.slider-category-product').attr('sliderToDisplayInMobile');

		$(this).slick({
			infinite: true,
			slidesToShow: sliderToDisplayInDesktop,
			slidesToScroll: 1,
			arrows: true,
			responsive: [
			    {
			      breakpoint: 1024,
			      settings: {
			        slidesToShow: sliderToDisplayInTablet
			      }
			    },
			    {
			      breakpoint: 768,
			      settings: {
			        slidesToShow: sliderToDisplayInMobile
			      }
			    }
			]
		});
		$(this).removeClass('d-none');
	});
});